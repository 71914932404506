/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */
/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */
/* from here split screen in shelf designer */
@font-face {
  font-family: "Arial-Regular";
  font-style: normal;
  font-weight: normal;
  src: url("../assets/fonts/Arial-MT-Std-Cond.ttf");
}
@font-face {
  font-family: "Arial-Medium";
  font-style: normal;
  font-weight: weight;
  src: url("../assets/fonts/FontsFree-Net-Arial-medium.ttf");
}
@font-face {
  font-family: "Arial-Bold";
  font-style: normal;
  font-weight: weight;
  src: url("../assets/fonts/Arial-MT-Std-Bold.ttf");
}
@font-face {
  font-family: "Montserrat-SemiBold";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Montserrat-SemiBold.ttf");
}
f body,
html {
  --ion-default-font: "Arial-Regular";
}

:root {
  --ion-backdrop-opacity: 0.9;
  --shelfy-test: #f00;
  --shelfy-shopping-card-bar-background: #F00020;
  --shelfy-shopping-card-bar-button-hover: #eee;
  --shelfy-shopping-card-bar-text: #fff;
  --shelfy-shopping-card-background: #fff;
  --shelfy-shopping-card-affiliate-background: #f5f5f5;
  --shelfy-shopping-card-price-summary: #000;
  --shelfy-shopping-card-price-buy: #777;
  --shelfy-shopping-card-price-not-buy: #ccc;
  --shelfy-shopping-card-icon-button: #F00020;
  --shelfy-shopping-card-icon-button-hover: #85143a;
  --shelfy-shopping-card-affiliate-button-text: #fff;
  --shelfy-shopping-card-affiliate-radius: 10px;
  --shelfy-cuppertino-button-background: #F0F0F0;
  --shelfy-cuppertino-button-background-active: #fff;
  --shelfy-cuppertino-button-text: #000;
  --shelfy-cuppertino-button-text-active: #F00020;
  --shelfy-cuppertino-button-scroll: #fff;
  --shelfy-cuppertino-button-scroll-text: #000;
  --shelfy-no-sceenes-found: #aaa;
  --shelfy-picker-selected-item: #F00020;
  --shelfy-picker-button-highlight: #000;
  --shelfy-picker-button-lower-prio: #E6E6E6;
  --shelfy-picker-button-lower-prio-text: #000;
  --shelfy-picker-image-background: #eee;
  --shelfy-picker-item-name: #000;
  --shelfy-picker-item-price: #666;
  --shelfy-picker-item-price-sale: #888;
  --shelfy-picker-item-price-sale-new: #F00020;
  --shelfy-picker-item-title-background: rgba(0, 0, 0, 0.5);
  --shelfy-picker-item-title-hover: rgba(240, 0, 32, 0.7);
  --shelfy-picker-item-title-hover-text: rgba(255, 255, 255, 0.7);
  --shelfy-picker-item-title-text: #fff;
  --shelfy-picker-item-button-top: rgba(255, 255, 255, 0.8);
  --shelfy-designs-border: #e2e2e2;
  --shelfy-designs-description: #777;
  --shelfy-designer-button-background: #E6E6E6;
  --shelfy-designer-button-background-icon: #F00020;
  --shelfy-designer-button-background-icon-hover: #F00000;
  --shelfy-designer-button-hover: #E6E6E6;
  --shelfy-designer-button-text: #777777;
  --shelfy-designer-button-text-mobile: #777777;
  --shelfy-filter-button-fill: #666;
  --shelfy-filter-button-fill-active: #F00020;
  --shelfy-filter-button-fill-active-hover: rgba(240, 0, 32, 0.7);
  --shelfy-filter-button-fill-text: #fff;
  --shelfy-filter-button-outline-active: #F00020;
  --shelfy-filter-button-outline-text: #666;
  --shelfy-filter-not-accordion: rgba(240, 0, 32, 0.2);
  --shelfy-filter-warning: #F00020;
  --shelfy-filter-color-chip: rgba(0, 0, 0, 0.67);
  --shelfy-filter-card: #F00020;
  --shelfy-filter-segment-active: #FFF;
  --shelfy-filter-segment-active-text: #000;
  --shelfy-item-fit-not: #fff;
  --shelfy-item-fit-not-bg: rgba(190, 29, 83, 0.4);
  --shelfy-item-fit-not-text: #be1d53;
  --shelfy-item-fit-warning: #c04600;
  --shelfy-menu: #666;
  --shelfy-menu-link: #F00020;
  --shelfy-menu-warning: #F00020;
  --shelfy-landing-cta: #F00020;
  --shelfy-landing-cta-text: #fff;
  --shelfy-landing-claim: #fff;
  --shelfy-landing-fact: #F00020;
  --shelfy-landing-background: #fff;
  --shelfy-landing-text: #000;
  --shelfy-landing-block: #fff;
  --shelfy-landing-be-creative: #333;
  --shelfy-landing-block-background: #F00020;
  --shelfy-landing-testimonials: #e7f3f3;
  --shelfy-landing-platforms-background: #fff;
  --shelfy-landing-social: #777777;
  --shelfy-landing-social-circle: #fff;
  --shelfy-landing-social-logo: #fff;
  --shelfy-landing-footer: rgba(240, 0, 32, 0.56);
  --shelfy-landing-footer-text: #F00020;
  --shelfy-header-toolbar: #666;
  --shelfy-header-toolbar-subtitle: #F00020;
  --shelfy-button-outline: #333;
  --shelfy-hr: #eee;
  --shelfy-button-only-text: #F00020;
  --shelfy-dropdown: #F00020;
  --shelfy-dropdown-border: #666;
  --shelfy-h2: #000;
  --shelfy-toggle-active: #F00020;
  --shelfy-toggle-inactive: #000;
  --shelfy-logoBG: #F00020;
  --shelfy-current-app-version: grey;
  --shelfy-btn-border-radius: 7px;
  --shelfy-icon-btn-size-small: 13px;
  --shelfy-icon-btn-size-big: 17px;
  --shelfy-designer-btn-bar-radius: 7px;
  --ion-color-primary: #399;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --ion-font-family: "Arial-Regular", Helvetica, sans-serif !important;
  --shelfy-font: "Arial-Regular", Arial, Helvetica, sans-serif;
  --shelfy-font-regular: "Arial-Regular", Arial, Helvetica, sans-serif;
  --shelfy-font-medium: "Arial-Medium", Arial, Helvetica, sans-serif;
  --shelfy-font-bold: "Arial-Bold", Arial, Helvetica, sans-serif;
  --shelfy-font-semi-bold: "Arial-SemiBold", Arial, Helvetica, sans-serif;
  --shelfy-font-filter-options: "Arial-Bold", Arial, Helvetica, sans-serif;
  --app-size-mobile-max: $app-size-mobile-max;
}

ion-modal {
  --backdrop-opacity: 0.9 !important;
}
ion-modal ion-header ion-toolbar:first-of-type,
ion-modal ion-header ion-toolbar {
  padding-top: 0;
}
ion-modal ion-footer ion-toolbar {
  padding-bottom: var(--ion-safe-area-bottom, 0);
}

ion-radio {
  --color-checked: var(--shelfy-filter-button-fill-active);
}

ion-button.ios.button.button-clear {
  --border-radius: var(--shelfy-btn-border-radius);
  --border-color: var(--shelfy-picker-button-lower-prio);
  --color: var(--shelfy-picker-button-lower-prio-text);
  font-size: 11px;
}
ion-button.ios.button.button-clear.shelfy-secondary-btn {
  --background: var(--shelfy-picker-button-lower-prio);
}
ion-button.ios.button.button-outline {
  --border-radius: var(--shelfy-btn-border-radius);
  --border-color: var(--shelfy-picker-button-lower-prio);
  --color: var(--shelfy-picker-button-lower-prio-text);
  font-size: 11px;
}
ion-button.ios.button.button-outline.shelfy-primary-btn {
  --color: var(--shelfy-picker-button-highlight);
  --border-color: var(--shelfy-picker-button-highlight);
}
ion-button.ios.button.button-solid {
  --border-radius: var(--shelfy-btn-border-radius);
  --border-color: var(--shelfy-picker-button-lower-prio);
  --color: var(--shelfy-picker-button-lower-prio-text);
  font-size: 11px;
}
ion-button.ios.button.button-solid.shelfy-primary-btn {
  --color: var(--shelfy-picker-button-highlight);
  --border-color: var(--shelfy-picker-button-highlight);
}
ion-button.ios.button.button-solid.choose-button {
  --background: var(--shelfy-cuppertino-button-text-active);
}

.pointer {
  cursor: pointer;
}

.primary-button {
  font-weight: bold !important;
}

.secondary-button {
  font-weight: normal !important;
}

.order-completion-alert {
  --backdrop-opacity: 67%;
  --width: calc(100vw - 30px);
  --min-width: 200px;
  --max-width: 300px;
}
.order-completion-alert .alert-wrapper {
  border: 2px solid var(--ion-color-primary);
}
.order-completion-alert .alert-title {
  color: var(--ion-color-primary);
  margin: 8px 0;
}
.order-completion-alert .alert-sub-title,
.order-completion-alert .alert-message > p {
  padding: 6px 0;
  line-height: 125%;
  font-size: 14px;
  color: var(--ion-color-step-600, #666);
}
.order-completion-alert .alert-sub-title ion-icon,
.order-completion-alert .alert-message > p ion-icon {
  font-size: 20px;
  vertical-align: middle !important;
  color: #666 !important;
}
.order-completion-alert .alert-button-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.order-completion-alert .shopping-cart-alert-button {
  height: 36px;
  font-size: 15px;
  --padding-start: 20px;
  --padding-end: 20px;
  border-radius: 64px;
  margin-bottom: 20px;
  min-width: 10%;
  max-width: 33%;
}
.order-completion-alert .back-button.alert-button.sc-ion-alert-ios {
  border: 1px solid var(--ion-color-primary);
  border: none;
  color: var(--ion-color-primary);
  background: transparent !important;
}
.order-completion-alert .continue-button {
  color: white;
  background: var(--ion-color-primary);
}

ion-icon.grey-icon {
  color: #666;
}

.no-cursor {
  cursor: default;
}

.currency-container {
  position: relative;
  width: auto;
  height: auto;
}

.strike-through {
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 0px;
  border-top: 1px solid #666;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal {
    --width: 100% !important;
    --height: 100% !important;
    --border-radius: 0 !important;
  }
}
.shelves-scenes-hacks-sheet .pane {
  padding-top: 0px;
  box-shadow: var(--cupertino-pane-shadow, 0 4px 16px rgba(0, 0, 0, 0.3));
  left: 0px;
  right: 0px;
  width: 100%;
  max-width: 100%;
}
.shelves-scenes-hacks-sheet .pane .draggable {
  top: -12px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  height: 12px;
  z-index: 0;
  bottom: initial;
  padding: 0;
}
.shelves-scenes-hacks-sheet .pane .draggable .move {
  height: 12px;
  width: 90px;
  top: 0;
  background-color: var(--shelfy-designer-button-background);
  border-radius: 25px 25px 0 0;
}

@media only screen and (min-width: 768px) {
  .shelves-scenes-hacks-sheet .pane {
    left: 0px;
    right: unset;
    width: calc(100% - 329px) !important;
    max-width: calc(100% - 329px);
  }
  .shelves-scenes-hacks-sheet .pane .cupertino-pane {
    height: 100%;
  }
}
.product-list-sheet .pane {
  right: 0;
  left: unset;
  width: 499px;
  --cupertino-pane-border-radius: 0;
  z-index: 100;
}

ion-popover.select-popover::part(backdrop) {
  background-color: rgba(0, 0, 0, 0.05);
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-2 21:32:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2022-4-2 21:40:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}
.header-ios {
  padding: 10px 8px 10px 18px;
}
.header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}

ion-modal:not(.modal-sheet) {
  --border-radius: 10px;
  --border-radius: 0px;
  --background: transparent;
  --backdrop-opacity: var(--ion-backdrop-opacity);
}
ion-modal:not(.modal-sheet) .modal-wrapper {
  background: none;
}
ion-modal:not(.modal-sheet) .header-ios {
  margin-top: 70px;
  border-radius: 10px 10px 0 0 !important;
  background-color: white;
}
ion-modal:not(.modal-sheet) .header-ios ion-toolbar {
  /* --background: transparent; // needed for black background in the header*/
  /* --color: #eee; */
  --color: #666666;
  font-weight: 300;
  --padding-start: 0px;
  --padding-end: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}
ion-modal:not(.modal-sheet) .header-ios ion-toolbar:last-of-type {
  --border-width: 0;
}
ion-modal:not(.modal-sheet) ion-content {
  border-radius: 0;
  background-color: white;
  --background: white;
  height: calc(100% - 120px);
}
ion-modal:not(.modal-sheet) ion-content .list-ios {
  background: transparent !important;
}
ion-modal:not(.modal-sheet) ion-content .list-ios ion-item,
ion-modal:not(.modal-sheet) ion-content .list-ios .item {
  --background: transparent;
}
ion-modal:not(.modal-sheet) ion-footer {
  background: white;
}
ion-modal:not(.modal-sheet).app-menu {
  --background: var(--ion-background-color, #fff);
}
ion-modal:not(.modal-sheet).app-menu ion-toolbar {
  --background: white;
}

ion-toolbar {
  --background: white;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal {
    --width: 95%;
    --height: 95%;
  }
}